<template>
  <div id="heat-map-chart">
    <div v-if="!!error"
         class="d-flex justify-content-center label"
    >
      {{error}}
    </div>
    <div v-else-if="!chartData.data"
         class="d-flex justify-content-center label"
    >
      {{staticText.noDataMessage}}
    </div>
    <div v-else>
      <div v-if="headerLabels.length>0"
           class="d-flex justify-content-end h-40px"
      >
        <div v-for="(headerLabel, headerIndex) in headerLabels"
             :key="headerIndex"
             :style="`width: ${1/totalColumns*(100-12)}%`"
             class="d-flex justify-content-center align-items-center"
        >
          <span :class="isCurrentDay(headerIndex)?'active-week-day':'label'">
            {{headerLabel}}
          </span>
        </div>
      </div>

      <div v-for="(row, index1) in Object.values(chartData.data)"
           class="d-flex h-25px"
           :key="index1"
      >
        <div class="w-12 d-flex align-items-center justify-content-end"
        >
          <span class="label hourPosition pr-1">
            {{Object.keys(chartData.data)[index1]}}
          </span>
        </div>
        <div
          v-for="(col, index2) in Object.values(row)"
          :key="index2"
          :style="`width: ${1/totalColumns*(100-12)}%`"
          class="heatmap-cell-wrapper"
        >
          <div v-if="isCurrentCell(col)"
               :style="`top: ${getTopPosition}`"
               class="marker"
          >
            <div class="marker__dot"></div>
            <div class="marker__line"></div>
          </div>

          <div :id="`heatmap-cell-${index1}-${index2}-${chartData.id}-${previewMode}`"
               :style="getColorScale(col)"
               class="heatMapCell"
          >
          </div>
          <b-tooltip :target="`heatmap-cell-${index1}-${index2}-${chartData.id}-${previewMode}`"
                     placement="top"
                     noninteractive
          >
            <span class="heatmap-tooltip-text">
              {{getTooltipLabel(col)}}
            </span>
          </b-tooltip>
        </div>
      </div>
      <div v-if="footerLabels.length>0"
           class="d-flex justify-content-end h-40px"
      >
        <div v-for="(footerLabel, index) in footerLabels"
             :key="index"
             :style="`width: ${1/totalColumns*(100-12)}%`"
             class="d-flex justify-content-center align-items-center"
        >
          <span class="label">{{footerLabel}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HeatMapChart",
  props: {
    chartData: {
      type: Object,
      default: () => {}
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false
    },
    currentDate: {
      type: Date, // required to indicate the active cell
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        calls: "Calls",
        call: "Call",
        noDataMessage: "No data to display",
        footerErrorMessage: "Inconsistent data: The number of elements in row does not match number of elements provided in footer",
        headerErrorMessage: "Inconsistent data: The number of elements in row does not match number of elements provided in header",
        noIdProvided: "No widget id provided",
        outOf: "out of"
      },
      error: null
    }
  },
  computed: {
    footerLabels () {
      if (this.chartData.footerLabels && Array.isArray(this.chartData.footerLabels)) {
        return this.chartData.footerLabels
      }
      return []
    },
    headerLabels () {
      if (this.chartData.headerLabels && Array.isArray(this.chartData.headerLabels)) {
        return this.chartData.headerLabels
      }
      return []
    },
    totalColumns () {
      const columns = this.footerLabels.length > 0 ? this.footerLabels : this.headerLabels
      return columns.length > 0 ? columns.length : 1
    },
    getTopPosition () {
      const maxHeight = 18 // height of row is 25px and 3px is padding hence 25-6(top-bottom-padding)-1(border)=18
      const top = (this.currentDate.getMinutes() / 60) * maxHeight
      // Note: 3 is added due to padding
      if (isNaN(top)) return "3px"
      if (top > maxHeight) return `${3 + maxHeight}px`
      return `${3 + top}px`
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.validateData()
  },
  methods: {
    validateData () {
      this.error = null
      if (!this.chartData.id && !this.previewMode) this.error = this.staticText.noIdProvided // Widget id is needed to correctly show tooltips
      // on the cells to uniquely define target in case there are multiple heatmaps in widget page

      // check if the header/footer data is equal to the number of columns
      if (this.footerLabels.length > 0 || this.headerLabels.length > 0) {
        for (const row of Object.values(this.chartData.data)) {
          if (this.footerLabels.length > 0 && Object.keys(row).length !== this.footerLabels.length) {
            this.error = this.staticText.footerErrorMessage
            return
          }
          if (this.headerLabels.length > 0 && Object.keys(row).length !== this.headerLabels.length) {
            this.error = this.staticText.headerErrorMessage
            return
          }
        }
      }
    },
    getColorScale (data) {
      const computedData = (data.successRatio / this.chartData.maxValue).toPrecision(2)
      const alpha = computedData > 0.0 ? computedData : 0.05
      return `background-color:rgba(197, 128, 93, ${alpha});`
    },
    getTooltipLabel (item) {
      const calls = item.total_calls.toString() === "1" ? this.staticText.call : this.staticText.calls
      return `${item.successPercentage} % (${item.success_calls} ${this.staticText.outOf} ${item.total_calls} ${calls})`
    },
    isCurrentCell (data) {
      /**
       * Checks if the data is regarding the current day and current hour and returns true
       */
      const currentHour = this.currentDate.getHours()
      // in backend week day starts from sunday = 1 and in javascript the weekday starts from sunday = 0, Hence add 1 to
      // map the days correctly
      const currentWeekDay = this.currentDate.getDay() + 1
      return data.created_at__hour === currentHour && data.created_at__week_day === currentWeekDay
    },
    isCurrentDay (dayIndex) {
      /**
       * Checks if the day-index(+1)is same as that of the current day
       * Note: adding 1 because dayIndex starts from 0(v-for loop) but actually, the day-number for monday is 1 (in
       * javascript date functions)
       */
      // index is used instead of string 'day' because the days can be in german or english
      const currentWeekDay = this.currentDate.getDay()
      return currentWeekDay === (dayIndex + 1)
    }
  }
}
</script>

<style scoped lang="scss">

#heat-map-chart {
  height: unset !important;
}

.heatMapCell{
  display:flex;
  justify-content: center;
  align-items: center;
  height:100%;
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
}

table {
  border-spacing: 10px;
  table-layout: fixed;
}
td {
    cursor: pointer;
    width: 3vw;
    height: 3vw;
    max-height: 3vw;
    max-width: 3vw;
    min-height: 3vw;
    min-width: 3vw;
}

.heatMapCell:hover {
  -webkit-transform:scale(1.1);
	transform:scale(1.1);
}

.heatmap-cell-wrapper{
  position: relative;
  padding: 3px 1px;
  border-top: 2px solid $slate06;
  border-top-width: thin;
}

.heatmap-cell-data{
  font-size: 12px;
  color: $slate50;
}

.heatmap-tooltip-text {
  font-size: 12px;
  font-weight: 400;
  color: $black50;
}

.h-25px{
  height: 25px;
}

.hide{
  display: none;
}

.label {
  font-size: 12px;
  font-weight: 400;
  color: $slate40;
}

.hourPosition {
  display: block;
  position: relative;
  top: -10px;
}

.tooltip.b-tooltip{
    opacity: 1 !important;
}

.marker {
  position: absolute;
  z-index: 506;
  left: 1px;
  width: calc(100% - 2px);
  display: flex;
  &__dot{
    background-color: $white;
    -webkit-border-radius: 50%;
    border: 2px solid $slate;
    border-radius: 50%;
    height: 6px;
    width: 6px;
    margin-top: -3px;
  }
  &__line{
    margin-top: -0.5px;
    height: 1px;
    background-color: rgba(127, 129, 151, 1);
    opacity: 0.5;
    width: 100%;
  }
}

.active-week-day{
  background-color: $slate;
  padding: 6px 14px;
  border-radius: 28px;
  color: $white;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
}

</style>
